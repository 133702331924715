import React, { useState } from 'react';
import './TestPage.css';

const TestPage = () => {
  const [testResults, setTestResults] = useState(null);

  const testPrompt = `Here are the tasks to perform:
1. Click the "Open New Tab" link - it will open example.com
2. In the new tab, find the text that says "Example Domain" and close the tab
3. Enter the text you found into the "Website Text" input field
4. Navigate to httpbin.org using the "Navigate" button
5. Click the "Open in New Tab" button to open httpstat.us, then close it
6. Type "Hello World" in the text input field
7. Press the spacebar in the spacepress field
8. Press enter in the enter key field
9. Single click the single click button
10. Double click the double click area
11. Triple click the triple click field to clear it
12. Click and hold the "hold me" button for 2 seconds
13. Fill out the form with any data and submit it
14. Describe what you see in the image below`;

  const validateTests = () => {
    const results = {
      passed: true,
      failures: []
    };

    // Check Website Text input (should contain "Example Domain")
    const websiteText = document.getElementById('google-text-input').value;
    if (websiteText !== 'Example Domain') {
      results.passed = false;
      results.failures.push('Website text does not match "Example Domain"');
    }

    // Check Hello World input
    const helloWorld = document.getElementById('text-input').value;
    if (helloWorld !== 'Hello World') {
      results.passed = false;
      results.failures.push('Text input does not contain "Hello World"');
    }

    // Check spacebar input (should contain a space)
    const spacebarInput = document.getElementById('spacebar-input').value;
    if (!spacebarInput.includes(' ')) {
      results.passed = false;
      results.failures.push('Spacebar field does not contain a space');
    }

    // Check triple click field (should be empty after triple click)
    const tripleClick = document.getElementById('triple-click').value;
    if (tripleClick !== '') {
      results.passed = false;
      results.failures.push('Triple click field was not cleared');
    }

    // Check form inputs
    const nameInput = document.querySelector('#test-form input[type="text"]').value;
    const emailInput = document.querySelector('#test-form input[type="email"]').value;
    if (!nameInput || !emailInput) {
      results.passed = false;
      results.failures.push('Form fields are not filled out');
    }
    
    // Update test results state
    setTestResults(results);
  };

  return (
    <div className="test-page">
      <h1>Chrome Autopilot Test Page</h1>
      
      <div className="test-section">
        <h2>Test Instructions</h2>
        <p>Copy the prompt below and paste it into the Chrome Autopilot extension:</p>
        <pre className="test-prompt">
          {testPrompt}
        </pre>
      </div>

      <div className="test-section">
        <h2>Tab Navigation Tests</h2>
        <a href="https://example.com" target="_blank" rel="noopener noreferrer">Open New Tab</a>
        <input type="text" placeholder="Website Text" id="google-text-input" />
        <button onClick={() => window.location.href = 'https://httpbin.org'}>Navigate</button>
        <button onClick={() => window.open('https://httpstat.us', '_blank')}>Open in New Tab</button>
      </div>

      <div className="test-section">
        <h2>Keyboard Input Tests</h2>
        <input type="text" placeholder="Type 'Hello World'" id="text-input" />
        <input type="text" placeholder="Press spacebar here" id="spacebar-input" />
        <input type="text" placeholder="Press enter here" id="enter-input" />
      </div>

      <div className="test-section">
        <h2>Click Tests</h2>
        <button id="single-click">Single Click Me</button>
        <div id="double-click" className="click-area">Double Click Me</div>
        <input type="text" id="triple-click" value="Triple click to clear this text" readOnly />
        <button id="hold-button">Hold me for 2 seconds</button>
      </div>

      <div className="test-section">
        <h2>Form Submission Test</h2>
        <form id="test-form" onSubmit={(e) => e.preventDefault()}>
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <button type="submit">Submit Form</button>
        </form>
      </div>

      <div className="test-section">
        <h2>Vision Test</h2>
        <div className="vision-test-area">
          <img src="/test-image.png" alt="" />
          <p>This is a test image for the AI to describe. It contains various shapes and colors arranged in a pattern.</p>
        </div>
      </div>

      <div className="test-section">
        <h2>Validate Test Results</h2>
        <button onClick={validateTests} className="validate-button">
          Validate Test Results
        </button>
        
        {testResults && (
          <div className={`test-results ${testResults.passed ? 'passed' : 'failed'}`}>
            <h3>{testResults.passed ? '✅ All Tests Passed!' : '❌ Some Tests Failed'}</h3>
            {!testResults.passed && (
              <ul className="failure-list">
                {testResults.failures.map((failure, index) => (
                  <li key={index}>{failure}</li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TestPage; 