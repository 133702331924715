import Footer from "components/Footer";
import Header from "components/Header";
import './styles.css';
import useAuth, { useForceReloadAfterLogin, openGoogleLogin } from "services/useAuth";
import { chromeWebstoreURL, useExtensionInstalled, useIsSidePanelOpen } from "services/useExtension";
import H1 from "./H1";

// const videoId = 'T6seCUow5b4' // 15 recording studios
const videoId = 'yEXX4KK5ZZQ' // piano marketplace

export default function Home() {
  const user = useAuth()
  
  // TODO: check extension version
  const extensionInstalled = useExtensionInstalled()
  useForceReloadAfterLogin()
  
  const isSidePanelOpen = useIsSidePanelOpen()
  const showOpenSidePanelButton = !isSidePanelOpen && user && extensionInstalled

  if (showOpenSidePanelButton) {
    // Add more detailed logging
    console.log('Extension state:', {
      extensionInstalled,
      hasWindow: typeof window !== 'undefined',
      hasChromeAutopilot: !!window?.ChromeAutopilot,
      hasMethod: !!window?.ChromeAutopilot?.sendToContentScript
    })

    try {
      if (window?.ChromeAutopilot?.sendToContentScript) {
        window.ChromeAutopilot.sendToContentScript('showOpenSidePanelButton')
      } else {
        // Wait a short time and try again, in case the content script hasn't loaded yet
        setTimeout(() => {
          if (window?.ChromeAutopilot?.sendToContentScript) {
            window.ChromeAutopilot.sendToContentScript('showOpenSidePanelButton')
          } else {
            console.warn('ChromeAutopilot.sendToContentScript still not available after delay')
          }
        }, 1000)
      }
    } catch (error) {
      console.error('Error sending message to content script:', error)
    }
  }
  
  return (
    <>
      <Header />

      <div className="content">
        <section className="hero">
          <H1 />
          <div className="subline">
            <b>Chr</b>ome <b>Aut</b>opilot is a <b>sid</b>ebar <b>ass</b>istant that can <b>use</b> your <b>bro</b>wser.
            <b>Tea</b>ch <b>it</b> how <b>to</b> <b>do</b> <b>thi</b>ngs <b>and</b> <b>it</b> <b>rem</b>embers!
          </div>
        </section>

        {!user && (
          <section className="heroButton">
            <div className="signInGoogle" onClick={openGoogleLogin}></div>
            {/* <div>No credit card needed -- try for free!</div> */}
          </section>
        )}
        {user && !extensionInstalled && (
          <section className="heroButton">
            <div className="get-button">
              <a href={chromeWebstoreURL}>Get Chrome Autopilot →</a>
            </div>
            <div>On the next page, click 'Add to Chrome' to install the Chrome Extension</div>
          </section>
        )}
        { showOpenSidePanelButton && (
          <section className="heroButton">
            <div className="get-button" id="openSidePanelContainer"></div>
          </section>
        )}
        {isSidePanelOpen && user && extensionInstalled && (!user.plan || user.plan === 'free') && (
          <section className="heroButton">
            <div className="get-button">
              <a href='/checkout'>✨ Upgrade to Premium</a>
            </div>
            <div>Subscribe for $20/month</div>
          </section>
        )}

        <section className="bg">
          <div className="video-container">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?loop=1&rel=0&playlist=${videoId}&controls=1&end=60&autoplay=1&mute=1`}
              title="Chrome Autopilot Demo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </section>

        <div className="faq-container">
          <section className="faq">
            <h2>FAQ</h2>
            <div className="faq-item">
              <dt>What is Chrome Autopilot?</dt>
              <dd>Chrome Autopilot is a Chrome extension that can perform tasks on your behalf. Simply talk into your microphone (or type a message) and Chrome Autopilot will perform the task you describe.
                <br />
                <br />
                <a href={chromeWebstoreURL}>Get the Chrome extension →</a>
              </dd>
            </div>
            <div className="faq-item">
              <dt>Can I teach Chrome Autopilot how to do things and will it remember?</dt>
              <dd>Yes! Chrome Autopilot remembers things you tell it to remember and other information from your conversations. If it doesn't know how to do something, you can teach it by telling it how to complete the task and it will remember the steps for next time.</dd>
            </div>
            <div className="faq-item">
              <dt>Can Chrome Autopilot see my screen?</dt>
              <dd>Chrome Autopilot can see snapshots of your web browser, but the in-page content of your web browser is never saved.</dd>
            </div>
            <div className="faq-item">
              <dt>Are my conversations private?</dt>
              <dd>Yes, your conversations are private. Chrome Autopilot may log your chat history internally to improve the service, but your chat history is not shared with anyone except for AI model providers like OpenAI.</dd>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  )
}

