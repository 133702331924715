import React, { useEffect, useState, useRef } from 'react'
import './Header.css'
import { useExtensionInstalled, chromeWebstoreURL } from 'services/useExtension'
import { useLocation, useParams } from 'react-router-dom'
import useAuth, { GoogleOneTapLogin, useForceReloadAfterLogin, showGoogleOneTapLogin } from 'services/useAuth'
import { useAction } from 'services/actions'

const tabs = [
  { uri: '/', label: 'Home' },
  { uri: '/chat', label: 'Chat' },
  { uri: '/agents', label: 'Agents' },
]

export default function Header() {
  const extensionInstalled = useExtensionInstalled()
  const user = useAuth()
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const toggleMenu = () => setShowMenu(!showMenu);
  useForceReloadAfterLogin()

  // get react router param
  const { id } = useParams();
  const action = useAction(id)
  let isActionTab = false
  if (action && !action.loading && !action.isAssistant) {
    isActionTab = true    
  }

  async function logout() {
    document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    document.cookie = `accessToken=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    setShowMenu(false)
    await window.ChromeAutopilot?.sendToSidePanel('userLoggedOut')
  }

  useEffect(() => {
    if (!user) {
      window.google.accounts.id.prompt()
    }
  }, [user])

  // Handle clicks outside the context menu
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target) && 
          !event.target.closest('.user-info') && showMenu) {
        setShowMenu(false);
      }
    }

    // Add event listener when menu is shown
    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu]);

  tabs.forEach(tab => {
    if (tab.uri === location.pathname) tab.class = 'tab selected'
    else if (tab.uri === '/' && location.pathname.startsWith('/chat/') && !isActionTab) tab.class = 'tab selected'
    else if (tab.uri === '/agents' && isActionTab) tab.class = 'tab selected'
    else tab.class = 'tab'
  })

  return (
    <div className="main-header">
    <header>
      {!user?.isLoading && !user && <GoogleOneTapLogin />}
      <span className="header-title">
        <a href="/">Chrome Autopilot</a> ✨
      </span>
      {/* <div className="tab-bar">
        {tabs.map((tab, i) => (
          <div key={i} className={tab.class} onClick={() => navigate(tab.uri)}>{tab.label}</div>
        ))}
      </div> */}

        {!user && (
          <div className="user-info">
            <div className="user-login" onClick={() => showGoogleOneTapLogin()}>Sign in</div>
            <span className="user-menu-icon" onClick={toggleMenu}>⋮</span>
          </div>
        )}
        {user && !user.isLoading && (
          <div className="user-info" onClick={toggleMenu}>
            <img alt='' className="user-avatar" src={user?.picture} width={30} />
            <span className="user-name">{user?.name}</span>
            <span className="user-menu-icon" onClick={toggleMenu}>⋮</span>
          </div>
        )}
        {showMenu && (
          <div className="context-menu" ref={menuRef}>
            {user?.plan === 'paid' && <a href="/billing" className="context-menu-item">My subscription</a>}
            {/* <a href="/memory" className="context-menu-item">Agent Memory</a> */}
            {user && user.plan !== 'paid' && (  
              <a href="/checkout" className="context-menu-item">Upgrade to Premium</a>
            )}
            <a href={chromeWebstoreURL} className="context-menu-item">Chrome Web Store</a>
            <a href="/terms-of-service" className="context-menu-item">Terms of Service</a>
            <a href="/privacy-policy" className="context-menu-item">Privacy Policy</a>
            {user && <div className="context-menu-item logout" onClick={logout}>Log out</div>}
          </div>
        )}
    </header>
    </div>
  )
}