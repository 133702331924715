import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AgentMemory from './components/AgentMemory'
import { GoogleOAuthProvider } from '@react-oauth/google';
import NotFound from './components/NotFound';

import GetStarted from 'components/GetStarted';
import Home from 'components/Home';
import TestPage from './components/TestPage';

export default function App() {
  return (
    <>
      <GoogleOAuthProvider clientId="419610695706-5kpuku8d5ckutikj0odvj5svlgrl4c9u.apps.googleusercontent.com">
        <Router>
          <Routes>
            <Route path="/get-started" element={<GetStarted />} />
            <Route path="/" element={<Home />} />
            <Route path="/memory" element={<AgentMemory />} />
            <Route path="/test" element={<TestPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </>
  );
}
