import { useState } from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import s from "./styles.module.scss";

const initialMemories = [
  {
    id: 1,
    name: "Youtube Playback Tip",
    content: "Press the spacebar to pause or unpause the video",
    type: "howto",
  },
  {
    id: 2,
    name: "How to check if someone is at the house",
    content: "Go to home.nest.com, make sure you have Blue River selected, and check if there is a car in the driveway",
    type: "howto",
  },
  {
    id: 3,
    name: "Family members",
    content: "Your wife is Amy, and you have 2 daughters, Brooklyn and Everly",
    type: "fact",
  },
  {
    id: 4,
    name: "Remind me to call my mom",
    content: "remind me at 10:00am tomorrow",
    type: "event",
    nextEvent: "2025-03-06T10:00:00Z"
  },
];

export default function AgentMemory() {
  const [memories, setMemories] = useState(initialMemories);
  const [editingMemory, setEditingMemory] = useState(null);
  const [editForm, setEditForm] = useState({
    name: "",
    content: "",
    type: "fact"
  });
  const [newMemoryText, setNewMemoryText] = useState("");
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEdit = (memory) => {
    setEditingMemory(memory.id);
    setEditForm({
      name: memory.name,
      content: memory.content,
      type: memory.type,
      nextEvent: memory.nextEvent
    });
  };

  const handleCancel = () => {
    setEditingMemory(null);
    setIsAddingNew(false);
    setNewMemoryText("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNewMemoryChange = (e) => {
    setNewMemoryText(e.target.value);
  };

  const handleSave = (id) => {
    setMemories(prev => 
      prev.map(memory => 
        memory.id === id 
          ? { ...memory, ...editForm } 
          : memory
      )
    );
    setEditingMemory(null);
  };

  const handleAddNew = () => {
    setIsAddingNew(true);
    setNewMemoryText("");
    setEditingMemory(null);
  };

  const handleSaveNew = async () => {
    if (!newMemoryText.trim()) {
      setIsAddingNew(false);
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // In a real implementation, this would be an API call to your backend
      // where the LLM would process the text and return structured data
      // For now, we'll simulate this with a timeout and dummy response
      
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // This would be the response from your backend after LLM processing
      const processedMemory = {
        id: Math.max(...memories.map(m => m.id)) + 1,
        content: newMemoryText,
        // These fields would be determined by the LLM on the backend
        name: "New memory", // Placeholder - would be determined by LLM
        type: "fact", // Placeholder - would be determined by LLM
      };
      
      // If it's an event type, the backend would also provide a nextEvent field
      if (Math.random() > 0.7) { // Just for simulation
        processedMemory.type = "event";
        processedMemory.nextEvent = new Date(Date.now() + 86400000).toISOString(); // tomorrow
      }
      
      setMemories(prev => [...prev, processedMemory]);
      setIsAddingNew(false);
      setNewMemoryText("");
    } catch (error) {
      console.error("Error saving memory:", error);
      // Handle error - show message to user
    } finally {
      setIsSubmitting(false);
    }
  };

  const MemoryCard = ({ memory }) => {
    const isEditing = editingMemory === memory.id;

    return (
      <div className={s.memoryCard} onClick={() => !isEditing && handleEdit(memory)}>
        <div className={s.memoryType}>{memory.type}</div>
        
        {isEditing ? (
          <div className={s.editForm} onClick={(e) => e.stopPropagation()}>
            <input 
              type="text" 
              name="name" 
              value={editForm.name} 
              onChange={handleChange} 
              placeholder="Memory name"
            />
            <textarea 
              name="content" 
              value={editForm.content} 
              onChange={handleChange} 
              placeholder="Memory content"
            />
            <select 
              name="type" 
              value={editForm.type} 
              onChange={handleChange}
            >
              <option value="howto">How To</option>
              <option value="fact">Fact</option>
              <option value="event">Event</option>
            </select>
            {memory.type === "event" && (
              <input 
                type="datetime-local" 
                name="nextEvent" 
                value={editForm.nextEvent ? new Date(editForm.nextEvent).toISOString().slice(0, 16) : ""} 
                onChange={handleChange} 
              />
            )}
            <div className={s.buttons}>
              <button className={s.saveBtn} onClick={() => handleSave(memory.id)}>Save</button>
              <button className={s.cancelBtn} onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        ) : (
          <>
            <div className={s.memoryName}>{memory.name}</div>
            <div className={s.memoryContent}>{memory.content}</div>
            {memory.nextEvent && (
              <div className={s.memoryDate}>
                Next: {new Date(memory.nextEvent).toLocaleString()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return <>
    <Header />
    <div className={s.page}>
      <h1>Agent Memory</h1>
      <article>
        <div className={s.memoryHeader}>
          <h2>Agent Memory</h2>
          <button className={s.addButton} onClick={handleAddNew}>Add New Memory</button>
        </div>
        
        {isAddingNew && (
          <div className={s.newMemoryForm}>
            <textarea 
              className={s.newMemoryTextarea}
              value={newMemoryText}
              onChange={handleNewMemoryChange}
              placeholder="Enter your memory here. Our AI will automatically process it."
            />
            <div className={s.buttons}>
              <button 
                className={s.saveBtn} 
                onClick={handleSaveNew}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Processing..." : "Save"}
              </button>
              <button 
                className={s.cancelBtn} 
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        
        <div className={s.memoryList}>
          {memories.map((memory) => (
            <MemoryCard key={memory.id} memory={memory} />
          ))}
        </div>
      </article>
    </div>
    <Footer />
  </>
}